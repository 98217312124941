import { render, staticRenderFns } from "./ProjectMemberEditor.vue?vue&type=template&id=29ff71cf&scoped=true&"
import script from "./ProjectMemberEditor.vue?vue&type=script&lang=js&"
export * from "./ProjectMemberEditor.vue?vue&type=script&lang=js&"
import style0 from "./ProjectMemberEditor.vue?vue&type=style&index=0&id=29ff71cf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29ff71cf",
  null
  
)

export default component.exports